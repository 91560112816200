<template>
  <div v-if="isLoaded">
    <!--begin::Card-->
    <div class="card card-custom">
      <!--begin::Body-->
      <div class="card-body py-8 px-8 py-lg-20 px-lg-10">
        <div class="row">
          <div class="offset-xxl-2 col-xxl-8">
            <h4 class="mb-10 font-weight-bold text-dark">
              Finanzen
            </h4>

            <!--begin::Table-->
            <div class="table-responsive">
              <table
                class="table table-head-custom table-vertical-center table-head-bg table-borderless"
              >
                <thead>
                  <tr class="text-left">
                    <th style="min-width: 120px" class="">
                      <span class="text-dark-75">Datum</span>
                    </th>
                    <th style="min-width: 120px">Fällig am</th>
                    <th style="min-width: 100px" class="text-right">Betrag</th>
                    <th style="min-width: 100px" class="text-right">Status</th>
                    <th style="min-width: 101px"></th>
                  </tr>
                </thead>
                <tbody>
                  <template
                    v-for="(subscription_order, n) in subscriptionOrders"
                  >
                    <template
                      v-for="(subscription_option,
                      i) in subscription_order.subscription_options"
                    >
                      <tr v-bind:key="'O' + i">
                        <td>
                          <span class="text-dark-75 d-block font-size-lg">{{
                            formatDateTime(
                              subscription_option.invoice.invoice_date
                            )
                          }}</span>
                        </td>
                        <td>
                          <span class="text-dark-75 d-block font-size-lg">{{
                            formatDateTime(
                              subscription_option.invoice.payment_due_date
                            )
                          }}</span>
                        </td>
                        <td class="text-right">
                          <span class="text-dark-75 d-block font-size-lg"
                            >CHF
                            {{
                              formatFloat(
                                subscription_option.invoice.invoice_amount
                              )
                            }}</span
                          >
                        </td>
                        <td class="text-right">
                          <span
                            class="label label-lg label-inline"
                            v-bind:class="
                              `label-light-${getInvoiceStatusType(
                                subscription_option.invoice.invoice_status
                              )}`
                            "
                          >
                            {{
                              subscription_option.invoice.invoice_status_display
                            }}
                          </span>
                        </td>
                        <td class="pr-0 text-right">
                          <router-link
                            :to="{
                              name: 'subscription-options-invoice',
                              params: {
                                orderId: subscription_order.id,
                                optionsId: subscription_option.id
                              }
                            }"
                            v-slot="{ href, navigate, isActive, isExactActive }"
                          >
                            <a
                              @click="navigate"
                              class="btn btn-light-success font-weight-bolder font-size-sm"
                              >Details</a
                            >
                          </router-link>
                        </td>
                      </tr>
                    </template>

                    <tr v-bind:key="'S' + n">
                      <td>
                        <span class="text-dark-75 d-block font-size-lg">{{
                          formatDateTime(
                            subscription_order.invoice.invoice_date
                          )
                        }}</span>
                      </td>
                      <td>
                        <span class="text-dark-75 d-block font-size-lg">{{
                          formatDateTime(
                            subscription_order.invoice.payment_due_date
                          )
                        }}</span>
                      </td>
                      <td class="text-right">
                        <span class="text-dark-75 d-block font-size-lg"
                          >CHF
                          {{
                            formatFloat(
                              subscription_order.invoice.invoice_amount
                            )
                          }}</span
                        >
                      </td>
                      <td class="text-right">
                        <span
                          class="label label-lg label-inline"
                          v-bind:class="
                            `label-light-${getInvoiceStatusType(
                              subscription_order.invoice.invoice_status
                            )}`
                          "
                        >
                          {{
                            subscription_order.invoice.invoice_status_display
                          }}
                        </span>
                      </td>
                      <td class="pr-0 text-right">
                        <router-link
                          :to="{
                            name: 'subscription-order-invoice',
                            params: {
                              orderId: subscription_order.id
                            }
                          }"
                          v-slot="{ href, navigate, isActive, isExactActive }"
                        >
                          <a
                            @click="navigate"
                            class="btn btn-light-success font-weight-bolder font-size-sm"
                            >Details</a
                          >
                        </router-link>
                      </td>
                    </tr>
                  </template>
                </tbody>
              </table>
            </div>
            <!--end::Table-->
          </div>
        </div>
      </div>
      <!--end::Body-->
    </div>
    <!--end::Card-->
  </div></template
>

<script>
import { mapGetters } from "vuex";
import { utilMixin } from "@/mixins/utilMixin";
import { LOAD_DASHBOARD_SUBSCRIPTION_ORDERS_LIST } from "@/store/dashboard.module";

export default {
  mixins: [utilMixin],
  name: "Invoices",
  data() {
    return {
      subscriptionOrders: {},
      isLoaded: false
    };
  },
  mounted() {
    this.loadData();
  },
  methods: {
    async loadData() {
      // Load first the pricing before the company is loaded
      await this.$store
        .dispatch(LOAD_DASHBOARD_SUBSCRIPTION_ORDERS_LIST)
        .then(() => {
          this.subscriptionOrders = this.getDashboardSubscriptionOrdersList;
        });
      this.isLoaded = true;
    }
  },
  computed: {
    ...mapGetters(["getDashboardSubscriptionOrdersList"])
  }
};
</script>

<style scoped></style>
